import { Injectable, inject } from '@angular/core';
import { PlatformService } from './platform.service';
import { GlobalEventsService } from './global-events.service';
import { CookieService } from 'ngx-unificator/services';
import { LanguageService } from './language.service';
import { UserGroupsService } from './user/user-groups.service';
import { UserService } from './user/user.service';
import { distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { CmsApiService } from './api/cms-api.service';
import { CmsContentMapperService } from './cms-content-mapper.service';
import { AbTestService } from '../ab-test/ab-test.service';

declare global {
  interface Window {
    OneSignal: any;
    OneSignalDeferred: any;
  }
}

declare var OneSignal;

@Injectable({
  providedIn: 'root',
})
export class OneSignalService {
  private _platform = inject(PlatformService);
  private _globalEvents = inject(GlobalEventsService);
  private _cookie = inject(CookieService);
  private _groups = inject(UserGroupsService);
  private _language = inject(LanguageService);
  private _user = inject(UserService);
  private _api = inject(CmsApiService);
  private _mapper = inject(CmsContentMapperService);
  private _ab = inject(AbTestService);


  /**
   * Get one signal id from CMS
   */
  handleId() {
    if (this._platform.isBrowser) {
      this._api.staticContentItem({ slug: '21bit-one-signal' }).pipe(
        first(),
        map(response => this._mapper.mapCmsData(
          response.data && response.data.item, { name: 'name' })[0]),
        tap((data) => this._onInit(data?.name)),
      ).subscribe();
    }
  }

  /**
   * Init one signal and apply id from CMS
   */
  private _onInit(id: string): void {
    window.OneSignalDeferred = window.OneSignalDeferred || [];
    window.OneSignalDeferred.push(async () => {
      await OneSignal.init({
        appId: id,
        notifyButton: {
          enable: true,
        },
        serviceWorkerParam: { scope: '/push/onesignal/' },
        serviceWorkerPath: 'push/onesignal/OneSignalSDKWorker.js',
      }).then(() => {
        this._autoUpdateUser();
        this._setExternalId();
      });
    });
  }

  // This function will work only if Onesignal init. So it will don`t work for localhost or stage
  // And will not send test users data. To make it work change in Onesignal admin panel in app test url to localhost
  private _autoUpdateUser() {
    this._globalEvents.routerNavigationEnd$.pipe(
      distinctUntilChanged(),
      tap((data) => {
        OneSignal.push(() => {
          this._setUserTags(this._createUserTagsObj());
        });
      }),
    ).subscribe();
  }

  private _setUserTags(data) {
    OneSignal?.User?.addTags(data);
  }

  private _createUserTagsObj() {
    const groups = this._ab.getAllAbTestsForOnesignal();
    console.log(groups);
    const resultObject = {};
    groups?.forEach(key => {
      resultObject[key] = true;
    });

    if (this._user.auth) {
      return {
        isUserRegistered: this._cookie.check('registered')?.toString(),
        isUserHadDeposit: (this._groups.isExistGroup('ID76') || this._user.accountWalletList.some((e => e.amount > 0)))?.toString(),
        userLanguage: this._language.current,
        isHaveBalance: this._user.accountWalletList.some((e => e.amount > 0))?.toString(),
        ...resultObject,
      };
    } else {
      return {
        isUserRegistered: this._cookie.check('registered')?.toString(),
        userLanguage: this._language.current,
        ...resultObject,
      };
    }
  }


  private _setExternalId() {
    OneSignal.push(async () => {
      if (OneSignal?.User?.PushSubscription?.id) {
        OneSignal.login(OneSignal.User.PushSubscription.id);
      }
    });
  }
}
